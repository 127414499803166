:root{
    --white: #FFFFFF;
    --black: #000000;
    --red: #F48123;
    /* --green1: #009961; */
    --green2 : #8EC542;
    --green3 : #AAD293;
    --green1 : #39A03D;
    --green4: #39A03D;
    --gray1 : #8A8988;
    --gray2 : #D6D5DA;
    --lightBlack: #282828;
}
:focus-visible {
    outline: unset !important;
}
html {
    scroll-behavior: smooth;
}
body{
    font-family: 'Quicksand', sans-serif !important;
    /* background-color: #f7f7f7 !important; */
}
a {
    text-decoration: none;
    color: var(--black);
}
.react-flow__handle{
    width: 0 !important;
    height: 0 !important;
    min-width: 0 !important;
    min-height: 0 !important;
    background: #3aa03d !important;
    border: unset;
}

.menu-sticky {
    margin: 0;
    width: 100%;
    z-index: 1;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    transition: all .7s;
}

.menu-sticky.sticky {
    position: fixed;
    background: rgba(255,255,255,.9);
    box-shadow: 0 0 20px -10px rgba(0,0,0,.8);
    padding: 0;
    -webkit-animation: slide-bottom .5s cubic-bezier(.25,.46,.45,.94) both;
    animation: slide-bottom .5s cubic-bezier(.25,.46,.45,.94) both
}


@-webkit-keyframes slide-bottom {
    0%,50% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes slide-bottom {
    50% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    0% {
        -webkit-transform: translateY(80px);
        transform: translateY(80px)
    }
}

.img-bounce img {
    -webkit-animation: bounce2 4s infinite ease-in-out;
    -o-animation: bounce2 4s infinite ease-in-out;
    -ms-animation: bounce2 4s infinite ease-in-out;
    -moz-animation: bounce2 4s infinite ease-in-out;
    animation: bounce2 4s infinite ease-in-out;
    cursor: pointer;
}

@-webkit-keyframes bounce2 {
    0% {
        transform: translateY(-5px)
    }

    50% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(-5px)
    }
}

@keyframes bounce2 {
    0% {
        transform: translateY(-5px)
    }

    50% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(-5px)
    }
}
.type-line::after{
    content: '';
    border: 0;
    width: 50px;
    height: 5px;
    background: var(--red);
    display: block;
    margin-top: 10px;
}
.type-line2::after{
    content: '';
    border: 0;
    width: 50px;
    height: 3px;
    background: var(--red);
    display: block;
    margin-top: 8px;
}


.overly-border {
    border: 1px solid #282828;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    z-index: 0;
}


.blog-button a {
    background: 0 0;
    color: #212121;
    border: none;
    border-radius: 25px;
    padding-right: 25px;
    line-height: 30px;
    display: inline-block;
    transition: all .4s ease-in-out 0s;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5rem;
    font-weight: 700;
    text-decoration: none;
}
.arrow-news{
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.5s ease;
    line-height: 1.5rem;
}
.blog-button a:hover{
    color: var(--green1)
}
.blog-button a:hover .arrow-news{
    color: var(--green1);
    transform: translateX(20%);
}
.banner-slide-image::before{
    content: '';
    width: 100%;
    height: 100%;
    background-color: #001d0778;
    position: absolute;
    top: 0;
    left: 0;;
}

.service__overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 80%;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
}

.bg-img {
    z-index: 1;
}

.service-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: -1px;
    bottom: 0;
    z-index: 2;
    border-radius: 12px;
    background-color: #fff;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-clip-path: polygon(30% 0,100% 55%,100% 100%,0 100%,0 0);
    clip-path: polygon(30% 0,100% 55%,100% 100%,0 100%,0 0);
}

.our-mission-wrap:hover {
    transform: translateY(-8px);
}
.our-service-wrap:hover {
    transform: scale(1.1);
}
.our-service-wrap:hover .text-service{
    transition: .4s;
    text-shadow : 0px 5px 15px #000000ca;
}

.our-mission-wrap, .our-service-wrap {
    transform: translateY(0);
    transition: .4s;
}
/* .service-item:hover::before{
    background-color: #3aa03d99;
    -webkit-clip-path: unset;
    clip-path: unset;
}
.service-item:hover .service__overlay{
    width: 100%;
    height: 100%;
}
.service-item:hover .text-service{
    color: #fff;
    font-weight: 500;
}
.service-item:hover .service-icon{
    justify-content: center;
    display: flex;
}
.service-item:hover .service-icon div{
    background-color: #fff;
}
.service-item:hover .text-service-title{
    text-align: center;
} */



.menu-item:hover ul.sub-menu {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
}
.sub-menu {
    background: #3aa03db3;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 98%;
    transform: scaleY(0);
    transform-origin: 0 0 0;
    transition: all .4s ease-in-out 0s;
    width: max-content;
    z-index: 99999!important;
    text-align: left;
    visibility: hidden;
    -webkit-box-shadow: 0 7px 7px 0 rgba(48,51,50,.09);
    -moz-box-shadow: 0 7px 7px 0 rgba(48,51,50,.09);
    box-shadow: 0 7px 7px 0 rgba(48,51,50,.09);
    padding: 10px 20px;
}

ul.sub-menu li {
    float: none;
    display: block;
    margin: 0;
    text-align: left;
    line-height: 25px;
    color: var(--white);
    font-weight: 700;
    line-height: 2.5rem;
}

ul.sub-menu li:hover{
    color: var(--red)
}

.line1::before{
    content: '';
    width: 150px;
    display: flex;
    margin: 0 15px;
    border-top: 2px solid var(--red);
}
.line2::before{
    content: '';
    width: 150px;
    display: flex;
    margin: 0 15px;
    border-top: 2px dashed var(--red);
}
.wrap-image-dialog:hover .button-next-image{
    opacity: 1 !important;
}
.button-next-image:hover{
    opacity: 1 !important;
}

.react-flow__node:hover {
    z-index: 1 !important;
  }